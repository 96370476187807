import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/storage';
import './index.scss';
import {addProtectedLinksToPage, registerServiceWorker} from './utils';

firebase.initializeApp({
  apiKey: 'AIzaSyA5NiEUjgKpDf0F_2KWEnDJQkxIUAyvYqY',
  authDomain: 'matmo.app',
  databaseURL: 'https://matmo-app-123.firebaseio.com',
  projectId: 'matmo-app-123',
  storageBucket: 'matmo-app-123.appspot.com',
  messagingSenderId: '1035218496780'
});

firebase.auth().onAuthStateChanged((user: firebase.User) => {
  const isUserLoggedIn = !!user;

  if (isUserLoggedIn) {
    onceUserLoggedIn();
  } else {
    const provider = new firebase.auth.GoogleAuthProvider();
    firebase
      .auth()
      .signInWithPopup(provider)
      .catch(console.error);
  }
});

function onceUserLoggedIn() {
  firebase
    .storage()
    .ref('protected-links.json')
    .getDownloadURL()
    .then(fetch)
    .then((res) => res.json())
    .then(addProtectedLinksToPage);
}

window.addEventListener('load', () => {
  if ('serviceWorker' in navigator) registerServiceWorker();

  const searchInput = document.getElementById('search');
  searchInput.focus();
  searchInput.onkeyup = (ev: KeyboardEvent) => {
    if (ev.keyCode === 13) {
      const searchValue: string = (ev.target as HTMLInputElement).value;
      window.location.href = `https://google.com/search?q=${searchValue}`;
    }
  };

  const today = new Date();
  const dayOfYear = Math.floor((today.getTime() - new Date(today.getFullYear(), 0, 0).getTime()) / 1000 / 60 / 60 / 24);
  const progressInCurrentYear = Math.floor((dayOfYear / 365) * 100);
  const progressPct = `${progressInCurrentYear}%`;
  document.getElementById('progress').style.width = progressPct;
  document.getElementById('day-percent').innerText = progressPct;
  document.getElementById('day-number').innerText = `Day ${dayOfYear}`;
});

fetch(
  'https://api.openweathermap.org/data/2.5/weather?lat=37.384848&lon=-122.0662968&appid=e26de5bfac584a4e709390e69550db46&units=imperial'
)
  .then((res) => res.json())
  .then((weatherData) => {
    const imgIcons = (weatherData.weather || [])
      .map(
        (w: any) => `
      <a class="weather-icon" href="https://www.google.com/search?q=weather" target="_blank">
        <img src="https://openweathermap.org/img/w/${w.icon}.png" alt="${w.description}">
      </a>
    `
      )
      .join('');

    document.getElementById('weatherData').innerHTML = `
      <div class="flex">
        <div class="min-temp">${Math.round(weatherData.main.temp_min)}</div>
        <div>----</div>
      </div>
      <div class="current-temp"><div>${Math.round(weatherData.main.temp)}</div></div>
      <div class="flex">
        <div>----</div>
        <div class="max-temp">${Math.round(weatherData.main.temp_max)} </div>
      </div>
    `;
    document.getElementById('weather-icons').innerHTML = `<div>${imgIcons}</div>`;
  });
