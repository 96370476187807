export function registerServiceWorker() {
  navigator.serviceWorker
    .register('/sw.js')
    .then((registration) => {
      console.log('SW registered: ', registration);
    })
    .catch((registrationError) => {
      console.log('SW registration failed: ', registrationError);
    });
}

export function addProtectedLinksToPage(linksData: ProtectedLinksData) {
  Object.keys(linksData).forEach((sectionId: keyof ProtectedLinksData) => {
    const section: HTMLElement = document.getElementById(sectionId);
    linksData[sectionId].map(createNewLinkElement).forEach(section.appendChild.bind(section));
  });
}

export function createNewLinkElement(link: Link): HTMLElement {
  const div = document.createElement('div');
  div.classList.add('entry');

  const img: HTMLImageElement = document.createElement('img');
  img.src = link.favIconSrc;

  const a: HTMLAnchorElement = document.createElement('a');
  a.href = link.href;
  a.text = link.displayName;

  div.appendChild(img);
  div.appendChild(a);

  return div;
}

export interface ProtectedLinksData {
  fun: Link[];
  money: Link[];
  code: Link[];
  career: Link[];
}

export interface Link {
  href: string;
  displayName: string;
  favIconSrc: string;
}
